import { render, staticRenderFns } from "./createSender.vue?vue&type=template&id=51d24d99"
import script from "./createSender.vue?vue&type=script&lang=js"
export * from "./createSender.vue?vue&type=script&lang=js"
import style0 from "./createSender.vue?vue&type=style&index=0&id=51d24d99&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports