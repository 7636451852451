<template>
  <div>
    <label class="label-for-input text-blue" v-if="label">{{ label }}</label>
    <strong v-show="required" style="color: red" class="ml-2">*</strong>
    <div class="upload-template">
      <div v-if="!uploadSuccess && !loading">
        <div @click="chooseFile">
          <h4 class="mt-4 mb-3"> {{ $t('common.uploadTitle') }}</h4>
          <i class="fas fa-file-alt display-4  d-block" :class="remarkFile ? 'mb-1' : 'mb-2'"/>
        </div>
        <div v-if="remarkFile" class="remark-file-upload" v-html="$t('campaigns.usingFileFrom.uploadDetail')">
        </div>
      </div>
      <div v-if="uploadSuccess && !loading">
        <div v-if="uploadFail">
          <i class="fas fa-times icon-closes mt-1" @click="removeFile()"/>
          <i class="fas fa-times-circle display-4 mb-3 d-block  text-danger mt-3 mb-2"/>
          <h3 v-html="$t('failed')" class="mb-3"></h3>
          <label class="text-danger">{{errorMessage}}</label>
        </div>
        <div v-else>
          <i class="fas fa-times icon-closes mt-1" @click="removeFile()"/>
          <i class="fas fa-check-circle display-4  d-block upload-file-success mt-3 mb-2"
             :class="remarkFile ? 'mb-1' : 'mb-2'"/>
          <h3 v-html="$t('success')" class="mb-3"></h3>
        </div>
      </div>
      <div v-if="!uploadSuccess && loading">
        <b-spinner
            class="mt-5 mb-1"
            style="width: 4rem; height: 4rem"
            label="Large Spinner"
            variant="warning"
            type="grow">
        </b-spinner>
        <h3 v-html="$t('processing')" class="mb-3"></h3>
      </div>
    </div>
    <input id="fileUpload" ref="fileContact" type="file" @change="loadFile" hidden>
  </div>
</template>

<script>
import contactApi from "@/repository/contactApi";

export default {
  name: "uploadFileContactComponent",
  props: {
    value: {
      type: String,
      request: false
    },
    label: {
      type: String,
      require: false
    },
    remarkFile: {
      type: Boolean,
      require: false
    },
    required: {
      type: Boolean,
      require: false
    }
  },
  data() {
    return {
      uploadSuccess: false,
      loading: false,
      errorMessage: '',
      uploadFail: false
    }
  },
  methods: {
    chooseFile() {
      document.getElementById("fileUpload").click();
    },
    loadFile(event) {
      let dataFile = new FormData();
      const file = event.target.files[0];
      dataFile.append('file', file)
      if (file) {
        this.loading = true
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64 = reader.result.split(',')
        let type = base64[0].split(':')
        type = type[1].split(';')
        type = type[0]
        base64 = base64[1]
        let data = {
          mimetype: type,
          buffer: base64,
        }
        contactApi.importFile(dataFile).then((response) => {
          this.uploadFail = false
          this.$emit('output', response.data)
          this.$emit('path_file', response.path_file)
        }).catch((err) => {
          this.uploadFail = true
          this.errorMessage = err.response?.data?.message
        }).finally(() => {
          this.uploadSuccess = true
          this.loading = false
        })
      };
      reader.readAsDataURL(file);
    },
    removeFile() {
      this.$emit('output', [])
      this.$refs.fileContact.value = ''
      this.uploadSuccess = false
    }
  },
}
</script>

<style scoped>
.upload-template {
  text-align: center;
  background-color: #C8EBFC52;
  border-radius: 12px;
  border: dashed;
  cursor: pointer;
  margin-bottom: 4px;
}

.remark-file-upload {
  padding: 1.5rem;
  font-size: 13px;
}

.upload-file-success {
  color: #479877;
}

.link-value {
  text-align: center;
  justify-content: center;
  padding: 1.5rem;
  font-size: 13px;
}

.icon-closes {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(149, 0, 0);
  position: relative;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
  top: 50%;
  left: 47%;
}

.icon-closes:hover {
  background-color: rgb(108, 94, 94);
  opacity: 1;
}
</style>

