import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = '/contact'
export default {
    findContactAll(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePath}/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    importFile(data) {
        return service.apiAuth.post(`${resourcePath}/import-file`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    exportContact(data) {
        return service.apiAuth.post(`${resourcePath}/export`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    viewContact(data) {
        return service.apiAuth.post(`${resourcePath}/${data.group_id}/search/${data.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    create(data) {
        return service.apiAuth.post(`${resourcePath}/create`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    validateFile(data) {
        return service.apiAuth.post(`${resourcePath}/import-validate`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    importAdd(data) {
        return service.apiAuth.post(`${resourcePath}/import-queue`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    exampleFileDownload(typeFile) {
        return service.apiAuth.get(`${resourcePath}/example-file?file_type=${typeFile}`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    exampleFileDownloadTXT() {
        return service.apiAuth.get(`/resource/example-contact-txt`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    createByText(data) {
        return service.apiAuth.post(`${resourcePath}/add-contact-by-text`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getById(id) {
        return service.apiAuth.get(`${resourcePath}/get/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    update(data, id) {
        return service.apiAuth.patch(`${resourcePath}/update/${id}`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    activeStatus(id) {
        return service.apiAuth.patch(`${resourcePath}/active/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    delete(id) {
        return service.apiAuth.delete(`${resourcePath}/delete/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    updateContact(data) {
        const mapBody = {
            phone: parseInt(data?.phone),
            first_name: data?.first_name,
            last_name: data?.last_name,
        }
        return service.apiAuth.patch(`${resourcePath}/${data.group_id}/update/${data.uid}`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    deleteContact(data) {
        return service.apiAuth.delete(`${resourcePath}/${data.group_id}/delete/${data.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getContactSummaryDetail() {
        return service.apiAuth.get(`/contact-summary`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    }

}