<template>
  <div>
    <div class="justify-content-between d-flex">
      <div class="text-start">
        <label class="label-for-input text-primary" v-if="label">{{ label }}</label>
        <strong v-show="required" style="color: red" class="ml-2">*</strong>
      </div>
      <div class="text-end">
        <div class="sub-title-info">
          {{textInfo || ''}}
        </div>
      </div>
    </div>
    <div class="upload-template" :class="{'upload-template-required': isRequiredError !== null && !isRequiredError}">
      <div v-if="!value">
        <div @click="chooseFile">
          <h5 class="mt-4 mb-3"> {{ $t('common.uploadTitle') }}</h5>
          <i class="fas fa-file-alt display-4 d-block" :class="remarkFile ? 'mb-1' : 'mb-4'"/>
        </div>
        <div v-if="remarkFile" class="remark-file-upload" v-html="$t('campaigns.usingFileFrom.uploadDetail')">
        </div>
      </div>
      <div v-if="value">
        <i class="fas fa-times icon-closes mt-1" @click="removeFile()"/>
        <i class="fas fa-check-circle display-4  d-block upload-file-success mt-3 mb-2"
           :class="remarkFile ? 'mb-1' : 'mb-3'"/>
        <div class="link-value">
          <a :href="value">{{ value }} </a>
        </div>
      </div>
    </div>
    <input id="fileUpload" ref="fileComponent" type="file" :accept="acceptFile" @change="loadFile" hidden>
  </div>
</template>

<script>
import resourceApi from "@/repository/resourceApi";

export default {
  name: "uploadFileComponent",
  props: {
    value: {
      type: String,
      request: false
    },
    label: {
      type: String,
      require: false
    },
    remarkFile: {
      type: Boolean,
      require: false
    },
    required: {
      type: Boolean,
      require: false
    },
    showPreview: {
      type: Boolean,
      require: false,
      default: false
    },
    isRequiredError: {
      type: Boolean,
      require: false
    },
    acceptFile: {
      type: String,
      default: '*/*'
    },
    isRequiredErrorText: {
      type: String,
      require: false
    },
    textInfo: {
      type: String,
      require: false
    }
  },
  methods: {
    chooseFile() {
      document.getElementById("fileUpload").click();
    },
    loadFile(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64 = reader.result.split(',')
        let type = base64[0].split(':')
        type = type[1].split(';')
        type = type[0]
        base64 = base64[1]
        let data = {
          mimetype: type,
          buffer: base64,
        }
        resourceApi.uploadImage(data).then((response) => {
          this.$emit('input', response.link)
        })
      };
      reader.readAsDataURL(file);
    },
    removeFile() {
      this.$refs.fileComponent.value = ''
      this.$emit('input', null)
    }
  },
}
</script>

<style scoped>
.upload-template {
  text-align: center;
  background-color: #C8EBFC52;
  border-radius: 12px;
  border: 2.5px dashed;
  cursor: pointer;
  margin-bottom: 4px;
}

.remark-file-upload {
  padding: 1.5rem;
  font-size: 13px;
}

.upload-file-success {
  color: #479877;
}

.link-value {
  text-align: center;
  justify-content: center;
  padding: 1.5rem;
  font-size: 13px;
}

.upload-template > div > .icon-closes {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(149, 0, 0);
  position: relative;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
  top: 50%;
  left: 47%;
}

.upload-template > div > .icon-closes:hover {
  background-color: rgb(108, 94, 94);
  opacity: 1;
}

.upload-template-required {
  border: 2.5px dashed red;
  background: #fff2f2;
}

.sub-title-info {
  color: #35b1e8;
}
</style>

