<template>
  <div role="group" id="radio-component">
    <div class="form-group mb-0">
      <b-row>
        <b-col class="col-auto" style="padding-right: 5px !important">
          <label class="label-select text-secondary" :class="{'landing-text':landingPage}" v-if="requireLabel"
                 style="font-size: 15px">{{ requireLabel }}</label>
        </b-col>
        <b-col
            v-if="required"
            align="left"
            class="col-2"
        >
          <strong v-if="true" style="color: red">*</strong>
        </b-col>
      </b-row>
      <b-form-radio-group :style="customStyle" style="margin-top: 6px; display: flex" @input="updateValue"
                          v-model="localValue" :disabled="isDisabled" v-if="landingPage"
                          id="custom-radio-landing-page-component"
                          class="custom-flex"
      >
        <b-form-radio v-for="val in option" :key="val.id" :value="val.value">
          {{ $t(val.name) }}
        </b-form-radio>
      </b-form-radio-group>
      <b-form-radio-group :style="customStyle" style="margin-top: 6px; display: flex" @input="updateValue"
                          v-model="localValue" :disabled="isDisabled" v-else>
        <b-form-radio v-for="val in option" :key="val.id" :value="val.value">
          {{ $t(val.name) }}
        </b-form-radio>
      </b-form-radio-group>
    </div>
  </div>
</template>
<script>

export default {
  name: "inputComponent",
  props: {
    landingPage: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      request: false,
      default: false
    },
    customStyle: {
      type: Object,
      request: false,
      default: ''
    },
    option: {
      type: [Array, Object],
      request: true
    },
    required: {
      type: Boolean,
      request: false
    },
    requireLabel: {
      type: String,
      request: false
    },
    value: {
      type: [String, Number, Date, Boolean],
      request: false,
      default: null
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e)
    },
  },
}
</script>
<style lang="scss">
#radio-component {
  .landing-text {
    color: #525252 !important;
  }

  #custom-radio-landing-page-component.custom-flex {
    @media (max-width: 768px) {
      flex-direction: column !important;
      gap: 8px !important;
    }

    @media (max-width: 992px) {
      margin-bottom: 12px;
    }
  }

  #custom-radio-landing-page-component {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #19CBACFF !important;
      background-color: #19CBACFF !important;
    }

    .custom-control-label {
      font-weight: 400;
      font-size: 15px;
      color: #525252 !important;
    }
  }
}
</style>