import { render, staticRenderFns } from "./verifyFormJuristic.vue?vue&type=template&id=360a7cbf&scoped=true"
import script from "./verifyFormJuristic.vue?vue&type=script&lang=js"
export * from "./verifyFormJuristic.vue?vue&type=script&lang=js"
import style0 from "./verifyFormJuristic.vue?vue&type=style&index=0&id=360a7cbf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360a7cbf",
  null
  
)

export default component.exports