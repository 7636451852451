<template>
  <div role="group" class="mb-2 pt-2">
    <b-row>
      <b-col class="col-10" style="padding-right: 5px!important;">
        <label class="label-for-input text-secondary mr-2" v-if="labelInput">{{ labelInput }} </label>
        <strong v-if="required" style="color: red">*</strong>
      </b-col>
      <!--      <b-col v-if="Require" align="right" class="col-2" style="padding-left: 5px!important;">-->
      <!--        <strong v-if="requireLabel" style="color: red">*</strong>-->
      <!--      </b-col>-->
    </b-row>
    <b-form-textarea
        :rows="rows"
        :max-rows="maxRows"
        :class="`input-area-text-box ${className} ${error}`"
        :id="inputId"
        :state="state"
        :v-model="value"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabledType==='true'"
        autocomplete="off"
        :value="value"
        @input="updateValue"
        @focusout="focusout"
    ></b-form-textarea>

    <div align="left" v-show="requireLabel">
      <label style="color: #FC6C6B; margin-bottom: 0px">{{ requireLabel }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "inputTextAreaComponent",
  props: {
    className: {
      type: String,
      request: false
    },
    showCount: {
      type: Boolean,
      request: false,
      default: false
    },
    max: {
      type: String,
      request: false
    },
    type: {
      type: String,
      request: false
    },
    state: {
      type: [String, Boolean],
      required: false,
      default: null
    },
    error: {
      type: String,
      request: false
    },
    requireLabel: {
      type: String,
      request: false
    },
    placeholder: {
      type: String,
      request: false
    },
    inputId: {
      type: String,
      request: false
    },
    rows: {
      type: String,
      request: false
    },
    maxRows: {
      type: String,
      request: false
    },
    labelInput: {
      type: String,
      request: false
    },
    disabledType: {
      type: String,
      request: false,
      default: "false"
    },
    bgColor: {
      type: String,
      request: false
    },
    value: {
      type: String,
      request: false
    },
    required: {
      type: Boolean,
      request: false,
      default: false
    },
  },
  data() {
    return {
      formatted: '',
      selected: ''
    }
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e)
    },
    focusout(e) {
      this.$emit('focusout', e)
    },
  }
}
</script>

<style scoped>
.input-area-text-box {
  background: #FFFFFF;
  border: 1px solid #D5DFE9;
  box-sizing: border-box;
  border-radius: 8px;
}

.label-for-input {
  /*font-family: 'Eudoxus Sans';*/
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px;
}

.input-disable-gray {
  background: #F8F8F8 !important;
  border: 0px;
}

.input-disable-blue {
  background: #EFF6FF !important;
  border: 1px solid #EFF6FF;
}

.error {
  border: 1px solid #FC6C6B !important;
}

</style>