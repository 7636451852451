<template>
  <div id="setting-create-sender" v-if="checkPermission('create', 'action', 'sender-names')">
    <topic-component :topic="`${$t('SideBarItems.setting.list.senderID')} / ${$t('setting.requestNew')}`"
                     :button_text="$t('SideBarItems.setting.list.senderID')"
                     name="SenderID"
                     is_arrow_back
                     :is_verify_button="userInfo?.identity_verify_status === 'verified'"
                     />
    <b-card>
      <div class="border-container mb-3" v-for="(sender, index) in tempArr" :key="sender.id">
        <b-row>
          <b-col sm="12" md="12" lg="6">
            <input-component :label-input="$t('setting.senderID.form.senderID')"
                             :label-info="$t('setting.senderID.form.detail')"
                             :state="$v.tempArr.$each[index].sender_name.required && duplicateMultiSenderName[`duplicate_${index}`]"
                             :require-label="checkMultiInputSenderId(index)"
                             required
                             :is-loading="multiSenderNameLoading[`loading_${index}`]"
                             max="11"
                             v-model="sender.sender_name"
                             @focusout="checkMultiSenderName(index)"
                             @keypress="isOnlyEnglishNumBer"
                             :disabledType="disabledSenderName"
            />
          </b-col>
          <b-col sm="12" md="12" lg="6">
            <radio-component :custom-style="customStyle"
                             :require-label="$t('setting.senderID.form.typeOfUse')"
                             required
                             @input="checkMultiSenderName(index)"
                             v-model="sender.sender_type"
                             :option="typeOperationOption"></radio-component>
          </b-col>
          <b-col sm="12" md="12" lg="12">
            <input-component :label-input="$t('setting.senderID.form.textSnippet')"
                             required
                             v-model="sender.text_snippet"
                             :state="$v.tempArr.$each[index].text_snippet.required"
                             :require-label="!$v.tempArr.$each[index].text_snippet.required ? $t('common.requiredField') : ''"
                             :placeholder="form.type === 'mkt'?'ตัวอย่าง : สวัสดีวันนี้มีโปรโมชั่นสำหรับการสมัครสมาชิก เป็นต้น':'ตัวอย่าง : OTP : XXXXXX (Ref: XXXXXX) Valid for 3 minutes'"/>

          </b-col>
          <b-col sm="12" md="12" lg="12">
            <div class="d-flex align-items-center justify-content-between mt-3">
              <b-form-checkbox v-model="sender.is_loa">
                <p class="m-0">
                  {{ `${$t('setting.multiple.conditions.attachLOA')} (Letter of Authorization: LOA)` }}
                </p>
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col sm="12" md="12" lg="12" class="mt-3" align="end">
            <b-button variant="danger" @click="removeSender(index)">
              <p class="m-0">
                {{
                $i18n.locale === "th" ? `${$t('common.delete_')}${$t('SideBarItems.setting.list.senderID')}` :
                `${$t('common.delete_')} ${$t('SideBarItems.setting.list.senderID')}`
                }}</p>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="border-container create">
        <b-row>
          <b-col sm="12" md="12" lg="6">
            <input-component :label-input="$t('setting.senderID.form.senderID')"
                             :label-info="$t('setting.senderID.form.detail')"
                             required
                             max="11"
                             :is-loading="senderNameLoading"
                             v-model="$v.form.sender_name.$model"
                             @focusout="checkSenderName"
                             @keypress="isOnlyEnglishNumBer"
                             :state="validateState('sender_name') && duplicateSenderName"
                             :require-label="checkInputSenderId"
                             :disabledType="disabledSenderName"
            />
          </b-col>
          <b-col sm="12" md="12" lg="6">
            <radio-component :custom-style="customStyle"
                             :require-label="$t('setting.senderID.form.typeOfUse')"
                             required
                             @input="checkSenderName"
                             v-model="form.type"
                             :option="typeOperationOption"></radio-component>
          </b-col>
          <b-col sm="12" md="12" lg="12">
            <input-component :label-input="$t('setting.senderID.form.textSnippet')"
                             required
                             :state="validateState('text_snippet')"
                             :require-label="validateState('text_snippet') !== null && !$v.form.text_snippet.required   ? $t('common.requiredField') : ''"
                             v-model="$v.form.text_snippet.$model"
                             :placeholder="form.type === 'mkt'?$t('setting.multiple.exampleMKT'):$t('setting.multiple.exampleOTP')"/>
          </b-col>
          <b-col sm="12" md="12" lg="12">
            <div class="d-flex align-items-center justify-content-between mt-3">
              <b-form-checkbox
                  v-model="form.is_loa"
              >
                <p class="m-0">
                  {{ `${$t('setting.multiple.conditions.attachLOA')} (Letter of Authorization: LOA)` }}
                </p>
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col sm="12" md="12" lg="12" class="mt-3" align="end" v-if="tempArr.length>0">
            <b-button variant="danger" @click="removeSenderLast">
              <p class="m-0">
                {{
                $i18n.locale === "th" ? `${$t('common.delete_')}${$t('SideBarItems.setting.list.senderID')}` :
                `${$t('common.delete_')} ${$t('SideBarItems.setting.list.senderID')}`
                }}</p>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="footer-hint d-flex align-items-center justify-content-between mt-3">
        <div class="footer-hint__container">
          <div>
            <p class="m-0 text-left text-danger text-nowrap"> {{ $t('freeTrials.note') }}</p>
          </div>
          <div style="display: flex; flex-direction: column; gap: 4px">
            <p class="m-0 text-left text-danger">- {{ $t('setting.multiple.conditions.maxSender') }}</p>
            <p class="m-0 text-left text-danger">- {{ $t('setting.multiple.conditions.verify') }}</p>
          </div>
        </div>
        <button class="btn btn-outline btn-custom-sender" @click="addNewSender" :disabled="checkAddNewSender">
          <span style="display: flex; gap: 6px">
            <p class="m-0">{{ $t('setting.multiple.button.newSender') }}
            <span class="hide-md">{{ $t('setting.multiple.button.conditions.moreThanOne') }}</span>
            </p>
            <b-spinner v-if="loadCreate" style="width: 18px; height: 18px"></b-spinner>
          </span>
        </button>
      </div>
    </b-card>
    <b-card class="mt-3" v-if="form.is_loa || is_show_loa">
      <!-- customer type -->
      <b-row class="mt-3">
        <!--  LOA -->
        <b-col sm="12" md="12" lg="12">
          <div class="mt-3">
            <upload-file-component
                v-model="fileLOA"
                single
                :label="`${$t('setting.multiple.LOA')} (Letter of Authorization: LOA)`"
                accept="image/png, image/jpeg, .pdf, .docx"
                :sub-label="$t('common.uploadTitle')"
                :text-info="`${$t('setting.senderID.form.accept_file')}, .docx`"
                is-button-right
                @update="downloadLOA"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3">
      <b-row>
        <b-col sm="12" md="12" lg="12">
          <input-component required :label-input="$t('setting.senderID.form.realCompanyOrStore')"
                           :state="validateState('company_url')"
                           :require-label="validateState('company_url') !== null && !$v.form.company_url.required   ? $t('common.requiredField') : ''"
                           v-model="$v.form.company_url.$model"/>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3">
      <b-row>
        <b-col sm="12" md="12" lg="12" class="mt-3">
          <label class="label-for-input">
            {{ $t('setting.senderID.conditions') }}
          </label>
          <div v-html="$t('setting.senderID.note')"/>
        </b-col>
        <b-col sm="12" md="12" lg="12" class="mt-3" align="center">
          <b-button variant="primary" @click="submitSender" :disabled="checkSubmit" class="w-25">
            <p class="m-0">{{ $t('setting.multiple.button.confirm') }}</p>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="userInfo">
      <VerifyModal @update="updateVerifyInfoSuccess" :customer-profile="userInfo"/>
    </div>
  </div>
</template>

<script>
import InputTextAreaComponent from "@/components/common/textareaComponent.vue";
import UploadFileComponent from "@/components/common/newUploadFileComponent.vue";
import InputComponent from "@/components/common/inputComponent.vue";
import SelectComponent from "@/components/common/selectComponent.vue";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import masterData from "@/common/masterData.json";
import functionsCommon from "@/common/functions";
import TopicComponent from "@/components/common/topicComponent.vue";
import RadioComponent from "@/components/common/radioComponent.vue";
import alert from "@/common/alert";
import senderIdApi from "@/repository/senderIdApi";
import functions from "@/common/functions";
import smsApi from "@/repository/smsApi";
import VerifyModal from "@/views/account/verify";

export default {
  name: "CreatSenderName",
  mixins: [validationMixin],
  components: {
    VerifyModal,
    RadioComponent,
    TopicComponent,
    SelectComponent,
    InputComponent,
    UploadFileComponent,
    InputTextAreaComponent
  },
  data() {
    return {
      loadCreate: false,
      maxContent: 4,
      tempArr: [],
      fileLOA: [],
      imgMoreDetail: [],
      approveLOA: false,
      senderNameLoading: false,
      approveMoreDetail: false,
      duplicateSenderName: true,
      duplicateMultiSenderName: {},
      multiSenderNameLoading: {},
      typeCustomerOption: [
        {
          value: 'personal',
          name: 'multipleCreateSender.juristicTypeList.personal'
        },
        {
          value: 'juristic',
          name: 'multipleCreateSender.juristicTypeList.juristic'
        }
      ],
      typeOperationOption: [{value: 'mkt', name: 'Marketing'}, {value: 'otp', name: 'OTP'}],
      form: {
        sender_name: "",
        type: "mkt",
        company_url: "",
        text_snippet: "",
        is_master_sender: false,
        is_loa: false
      },
      typeList: masterData.typeList,
      juristic_type_list: masterData.juristic_list,
      disabledSenderName: false,
      is_show_loa:false
    }
  },
  validations: {
    tempArr: {
      $each: {
        text_snippet: {
          required
        },
        sender_name: {
          required
        },
      }
    },
    form: {
      sender_name: {
        required
      },
      type: {
        required
      },
      text_snippet: {
        required
      },
      company_url: {
        required
      }
    },
  },
  mounted() {
    this.setUpMultiple()
  },
  computed: {
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    },
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
    checkInputSenderId() {
      let errors = []
      if (!_.isNil(this.validateState('sender_name'))) {
        !this.$v.form.sender_name.required && errors.push(this.$t('common.requiredField'))
        !this.duplicateSenderName && errors.push(this.$t('setting.multiple.err.userExist'))
        return errors[0]
      }
      return errors[0]
    },
    checkSubmit() {
      const checkMultiDuplicate = Object.values(this.duplicateMultiSenderName).some(value => value === false);
      const verifyCondition = checkMultiDuplicate || this.$v.form.text_snippet.$invalid || this.$v.form.sender_name.$invalid || this.$v.tempArr.$each.$invalid
          || this.$v.form.company_url.$invalid || !this.duplicateSenderName
      return verifyCondition
    },
    checkTaxID() {
      let errors = []
      if (!_.isNil(this.validateState('tax_id'))) {
        if (!this.$v.form.tax_id.$dirty) return errors
        !this.$v.form.tax_id.required && errors.push(this.$t('common.requiredField'))
        !this.$v.form.tax_id.maxLength && errors.push(this.$t('setting.multiple.err.fillMoreSeven'))
        !this.$v.form.tax_id.minLength && errors.push(this.$t('setting.multiple.err.fillMoreSeven'))
        return errors[0]
      }
      return errors[0]
    },
    checkAddNewSender() {
      const checkMultiDuplicate = Object.values(this.duplicateMultiSenderName).some(value => value === false);
      const verifyCondition = this.tempArr.length === this.maxContent || checkMultiDuplicate || this.$v.form.sender_name.$invalid || this.$v.form.text_snippet.$invalid || !this.duplicateSenderName || this.$v.tempArr.$each.$invalid
      return verifyCondition
    },
    customStyle() {
      return {
        gap: '80px'
      }
    }
  },
  methods: {
    downloadLOA() {
      const LOA_example = 'https://assets.sms2pro.com/public/letter-of-authorization.docx'
      const link = document.createElement("a");
      link.href = LOA_example;
      link.click();
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    removeSenderLast() {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('setting.multiple.modal.deleteTittle'),
        text: this.$t('setting.multiple.modal.deleteText'),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.confirm'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          const lastArr = this.tempArr.length - 1
          this.form.sender_name = this.tempArr[lastArr].sender_name
          this.form.type = this.tempArr[lastArr].sender_type
          this.form.text_snippet = this.tempArr[lastArr].text_snippet
          this.form.is_loa = this.tempArr[lastArr].is_loa
          setTimeout(() => {
            this.tempArr.splice(lastArr, 1)
            alert.deleteSuccess(this)
          }, 50)
        }
      })
    },
    setUpMultiple() {
      for (let i = 0; i < this.maxContent; i++) {
        this.duplicateMultiSenderName[`duplicate_${i}`] = true
        this.multiSenderNameLoading[`loading_${i}`] = false
      }
    },
    checkMultiInputSenderId(index) {
      let errors = []
      !this.$v.tempArr.$each[index].sender_name.required && errors.push(this.$t('common.requiredField'))
      !this.duplicateMultiSenderName[`duplicate_${index}`] && errors.push(this.$t('setting.multiple.err.userExist'))
      return errors[0]
    },
    checkMultiSenderName(index) { // Instead of updating a specific property, you can try updating the entire object. Vue's reactivity system may not always detect changes in nested properties.
      const sender_name = _.toUpper(this.tempArr[index].sender_name)
      if (sender_name?.length > 0) {
        this.multiSenderNameLoading = {...this.multiSenderNameLoading, [`loading_${index}`]: true};
        const data = {
          sender_name: sender_name,
          sender_type: this.tempArr[index].sender_type
        }
        senderIdApi.checkMultipleSenderExist(data).then((res) => {
          if (res.codeSYS === '001') {
            this.duplicateMultiSenderName = {
              ...this.duplicateMultiSenderName,
              [`duplicate_${index}`]: res?.is_available
            };
          }
        }).catch((err) => {
          throw err
        }).finally(() => {
          this.multiSenderNameLoading = {...this.multiSenderNameLoading, [`loading_${index}`]: false};
        })
      }
    },
    updateVerifyInfoSuccess () {
      this.$router.push('/Account').catch(() => {})
    },
    checkSenderName() {
      const sender_name = _.toUpper(this.form.sender_name)
      if (sender_name?.length > 0) {
        this.senderNameLoading = true
        const data = {
          sender_name: sender_name,
          sender_type: this.form.type
        }
        senderIdApi.checkMultipleSenderExist(data).then((res) => {
          if (res.codeSYS === '001') {
            this.duplicateSenderName = res?.is_available
            if (this.duplicateSenderName && this.tempArr.length > 0) {
              this.duplicateSenderName = !this.tempArr.some(obj => obj.sender_name.toUpperCase() === this.form.sender_name.toUpperCase())
              this.senderNameLoading = false
            }
          }
        }).catch((err) => {
          throw err
        }).finally(() => {
          this.senderNameLoading = false
        })

      }
    },
    async submitSender() {
        if (this.userInfo?.identity_verify_status === 'verified') {
          this.$swal.fire({
            icon: 'warning',
            title: this.$t('setting.multiple.modal.confirmTittle'),
            text: this.$t('setting.multiple.modal.confirmText'),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#EA5455',
            cancelButtonColor: '#7367F0',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.send'),
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$swal.fire({
                html: this.$t('processing'),
                title: '<div style="margin-top:18px; width: 100%; display: flex; justify-content: center; align-items: center"><div class="text-primary-fade-out"></div></div>',
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                  const spinner = document.querySelector('.spinner-border');
                  if (spinner) {
                    spinner.style.width = '80px';
                    spinner.style.height = '80px';
                    spinner.style.borderRightColor = 'transparent';
                  }
                }
              });
              await this.createMultipleSender()
            }
          })
        } else if (this.userInfo?.identity_verify_status === 'waiting-approve') {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify_waiting.svg'),
            title: this.$t('common.not_been_approve'),
            text: this.$t('common.please_check_your_auth_status_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_check_your_auth_status_2'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.check'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/Account').catch(() => {
              })
            }
          })
        } else if (this.userInfo.identity_verify_status === 'rejected') {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify_failed.svg'),
            title: this.$t('common.not_been_success_auth'),
            text: this.$t('common.please_re_auth_enable_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_re_auth_enable_2'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.verify'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/Account').catch(() => {
              })
            }
          })
        } else {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify.svg'),
            title: this.$t('common.has_not_been_auth'),
            text: this.$t('common.please_verify_your_credential_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_verify_your_credential_2'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.verify'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$bvModal.show('modal-verify-account')
            }
          })
      }
    },
    clearData() {
      this.tempArr = []
      this.form.juristic_type = 'personal'
      this.form.type = "mkt"
      this.form.company_name = ""
      this.form.tax_id = ""
      this.form.company_url = ""
      this.form.sender_name = ""
      this.form.text_snippet = ""
      this.form.is_loa = false
      this.fileLOA = []
      this.imgMoreDetail = []
      this.loadCreate = false
      this.senderNameLoading = false
      this.$v.form.$reset()
      this.$v.tempArr.$reset()
      this.setUpMultiple()
    },
    createMultipleSender() {
      let imgLink = this.imgMoreDetail.map(res => res.link)
      const tempData = {
        sender_name: _.toUpper(this.form.sender_name),
        sender_type: this.form.type,
        text_snippet: this.form.text_snippet,
        is_loa: this.form.is_loa
      };
      this.tempArr.push(tempData)
      const tempArr = this.tempArr.map(item => {
        return {...item, sender_name: item.sender_name.toUpperCase()};
      });
      const data = {
        senders: tempArr,
        url: this.form.company_url,
        loa_file: this.fileLOA[0]?.link ? this.fileLOA[0]?.link : null,
        other_file: this.approveMoreDetail ? imgLink ? imgLink : [] : []
      }
      senderIdApi.createMultipleSender(data).then((res) => {
        if (res) {
          const successObj = {
            type: 'success',
            title: this.$t('setting.multiple.modal.successTitle'),
            text: false,
            time: 1500
          }
          this.customAlert(successObj)
        }
      }).catch((err) => {
        const errObj = {
          type: 'error',
          title: this.$t('setting.multiple.modal.failTitle'),
          text: err?.response?.data?.message,
          time: false
        }
        this.customAlert(errObj)
      })
    },
    customAlert(obj) {
      this.$swal.fire({
        icon: obj.type,
        title: obj.title,
        html: obj.text ? '<p>' + this.$t('common.somethingWrong') + '</p>' + obj.text : ' ',
        showConfirmButton: obj.type === 'error',
        timer: obj.time
      }).then(() => {
        if (obj.type === 'success') {
          this.clearData()
          this.$router.push({path: '/Setting/SenderID'})
        } else {
          const lastArr = this.tempArr.length - 1
          this.form.sender_name = this.tempArr[lastArr].sender_name
          this.form.type = this.tempArr[lastArr].sender_type
          this.form.text_snippet = this.tempArr[lastArr].text_snippet
          this.tempArr.splice(lastArr, 1)
        }
      })
    },
    checkNumber(e) {
      return functionsCommon.checkNumber(e)
    },
    removeSender(index) {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('setting.multiple.modal.deleteTittle'),
        text: this.$t('setting.multiple.modal.deleteText'),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.confirm'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.tempArr.splice(index, 1)
          alert.deleteSuccess(this)
        }
      })
    },
    addNewSender() {
      this.loadCreate = true
      const data = {
        sender_name: _.toUpper(this.form.sender_name),
        sender_type: this.form.type,
        text_snippet: this.form.text_snippet,
        is_loa: this.form.is_loa
      };
      setTimeout(() => {
        this.tempArr.push(data);
        this.form.sender_name = ''
        this.form.text_snippet = ''
        this.form.is_loa = false
        this.form.type = 'mkt'
        this.loadCreate = false
        this.duplicateSenderName = true
        this.$v.form.$reset()
        this.tempArr = Array.from(new Set(this.tempArr.map(obj => obj.sender_name))).map(sender_name => this.tempArr.find(obj => obj.sender_name === sender_name));
      }, 200)
    },
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    isOnlyEnglishNumBer(e) {
      return functionsCommon.isOnlyEnglishNumberAndUnderscore(e)
    },
  },
  watch: {
    'form.sender_name'(newVal) {
      this.form.sender_name = newVal.replace(" ", "")
    },
    tempArr: {
      handler: function (newItems) {
        newItems.forEach((newItem, index) => {
          const item = newItem.sender_name;
          this.tempArr[index].sender_name = item.replace(" ", "")
          this.is_show_loa = _.some(this.tempArr, ['is_loa', true])
        });
      },
      deep: true,
    },
  }
}
</script>
<style lang="scss">
.text-primary-fade-out {
  position: relative;
  width: 60px; /* Adjusted width to accommodate the larger border */
  height: 60px; /* Adjusted height to accommodate the larger border */
  border-radius: 10rem;
  border-width: 20px; /* Increased border width */
  background: conic-gradient(
          var(--secondary-color) 0deg 36deg,
          transparent 36deg 79deg,
          var(--primary-color),
          var(--primary-color),
          var(--secondary-color)
  );
  box-shadow: 0px 0px 100px -50px color;
  animation: animate 1s linear infinite;
}

.text-primary-fade-out::before {
  position: absolute;
  content: "";
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  border-radius: 10rem;
  border: 3px solid white;
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#setting-create-sender {
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    border-color: transparent;
  }

  .grid-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 1024px) {
      grid-template-columns: repeat(1, 1fr);

      .grid-custom-mt {
        margin-top: 8px;
      }
    }
  }

  .condition-verify {
    padding: 1rem;
    width: 100%;
    color: #2F2E2E;
    background: #FFF6E8;
    border-radius: 20px;

    .content {
      font-size: 12px;
      margin-top: .5rem;
      word-break: break-word;
    }
  }

  .border-container {
    padding: 24px 16px;
    border-radius: 20px;
    border: 1px solid #E9E9E9;
  }

  .footer-hint {
    @media (max-width: 768px) {
      flex-direction: column !important;
      gap: 8px;

      .btn-custom-sender {
        width: 100%;
      }
    }

    .footer-hint__container {
      display: flex;
      gap: 8px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

  }

  .hide-md {
    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>