<template>
  <div>
    <div class="d-flex " :class="iconTab? 'mb-2': ''">
      <div class="mt-2" v-if="label">
        <label class="label-upload">{{ label }}</label>
        <p v-if="required" class="req-text">*</p>
      </div>
    </div>
    <div class="content-box" :class="`${custom_class}`">
      <div class="wrapper-box mb-2 w-100" v-for="(img, index) in previewImage" :key="index"
           style="background-color: rgba(158,158,158,0.15) ; padding: 3px; border-radius: 8px">
        <div class="preview-file-box " v-if="previewImage.length > 0">
          <div class="box">
            <i class="fas fa-times close"
               v-if="closeIcon"
               @click="clearImage(index)"/>
            <div class="pl-2">
              <span><a :href="img" target="_blank">{{ img }}</a></span>
            </div>
          </div>
        </div>
      </div>
      <div class="upload-file-box-full" v-if="closeIcon && !maxFile">
        <input type="file" ref="fileComponent1" @change="handleFileChange" :accept="accept || '*'">
        <div class="content-upload-full">
          <img src="~@/assets/images/icon/file.svg" alt="add-img" width="80"/>
          <div class="mt-2">
            <label>{{ subLabel }}</label>
            <div :class="`${errorText || ''} error-text`"><sup v-if="limitFileError">*</sup>{{ limitFileError }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import resourceApi from "@/repository/resourceApi";
import common from "@/common/functions";

export default {
  name: "uploadFileMultipleComponent",
  components: {},
  props: {
    custom_class: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    subLabel: {
      type: String,
      required: false
    },
    value: {
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    previewClassName: {
      type: String,
      required: false,
    },
    iconCloseClass: {
      type: String,
      required: false,
    },
    sizeFile: {
      type: Number,
      required: false,
    },
    accept: {
      type: String,
      required: false,
    },
    errorText: {
      type: String,
      required: false,
    },
    iconTab: {
      type: Boolean,
      required: false,
    },
    activeIconTab: {
      type: String,
      required: false,
    },
    sizeFileKB: {
      type: Number,
      required: false,
    },
    closeIcon: {
      default: true,
      required: false
    },
    maxFile: {
      type: Boolean
    }
  },
  data() {
    return {
      previewImage: [],
      limitFileError: '',
    }
  },
  mounted() {
    if (this.value) {
      this.previewImage = this.value
    }
  },
  emits: ['input'],
  methods: {
    handleFileChange(e) {
      let file = e.target.files
      let maximumSize = this.sizeFile * 1024 * 1024
      let reader = new FileReader
      reader.onload = e => {
        let imageBuffer = e?.target?.result.split(',')
        let mapData = {
          mimetype: file[0].type,
          buffer: imageBuffer[1],
        }
        resourceApi.uploadImage(mapData).then((response) => {
          this.previewImage.push(response.link)
          this.$emit('input', this.previewImage)
          this.$refs.fileComponent1.value = ''
        }).catch((err) => {
          this.$refs.fileComponent1.value = ''
          if (err.response.status === 413) {
            this.limitFileError = this.$t('setting.multiple.err.maxSize')
          } else {
            this.limitFileError = this.$t('report.campaignList.modalRefundAllCredit.textDestitute')
          }
        })
      }
      const accept = ['application/pdf', 'image/png', 'image/jpeg'];
      const fileSizeInMegabytes = file[0]?.size ? file[0].size / (1024 * 1024) : 11;
      if (accept.includes(file[0].type)) {
        if (fileSizeInMegabytes.toFixed(2) <= 10) {
          this.limitFileError = ''
          reader.readAsDataURL(file[0]);
        } else {
          const accept = ['image/png', 'image/jpeg'];
          if (accept.includes(file[0].type)) {
            common.resizeImage(file[0], (resizedDataURL) => {
              const buffer = resizedDataURL.split(',')
              let mapData = {
                mimetype: file[0].type,
                buffer: buffer[1],
              }
              resourceApi.uploadImage(mapData).then((response) => {
                this.previewImage.push(response.link)
                this.$emit('input', this.previewImage)
                this.$refs.fileComponent1.value = ''
              }).catch((err) => {
                this.$refs.fileComponent1.value = ''
                if (err.response.status === 413) {
                  this.limitFileError = this.$t('setting.multiple.err.maxSize')
                } else {
                  this.limitFileError = this.$t('report.campaignList.modalRefundAllCredit.textDestitute')
                }
              })
            });
          } else {
            this.limitFileError = this.$t('setting.multiple.err.maxSize')
          }
        }
      } else {
        this.limitFileError = this.$t('setting.multiple.err.notSupport')
      }

    },
    clearImage(index) {
      this.previewImage.splice(index, 1)
      this.$emit('input', this.previewImage)
      this.limitFileError = ''
    },
    checkObj(dataValue) {
      return _.isObject(dataValue) ? Object.keys(dataValue).length === 0 : true
    }
  },
  watch: {
    'value'(newData) {
      this.previewImage = newData
    }
  }
}
</script>

<style scoped>
.wrapper-box {
  display: inline-block;
}

.content-box {
  display: inline-flex;
  flex-direction: unset;
  flex-flow: row wrap;
  border-radius: 10px;
  vertical-align: middle;
  padding: 10px;
  text-align: center;
  justify-content: flex-start;
  width: 100%;
}

.preview-file-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px;
}

.preview-file-box > .box {
  padding: 0 10px;
}

.preview-file-box > .box > .close {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(149, 0, 0);
  position: absolute;
  top: 1px;
  right: 19px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
}

.preview-file-box > .box > .close:hover {
  background-color: rgb(108, 94, 94);
  opacity: 1;
}

.preview-file-box > .box > img {
  border: 2px dashed #D6D6D6;
  width: 100%;
  height: 220px;
  object-fit: cover;
  background: #FFFFFF center center;
  border-radius: 5px;
}

.upload-file-box {
  padding: 0;
  border: 2px dashed #D6D6D6;
  box-sizing: border-box;
  border-radius: 5px;
  height: 220px;
  cursor: pointer;
  background: #fcfcfc center center;
  text-align: center;
  justify-content: center;
  width: 184px;
  margin-top: 10px;
}

.upload-file-box > input[type="file"]::-webkit-file-upload-button {
  padding: 5px 12px;
  color: #fff;
  font-size: 1rem;
  transition: all .4s;
  cursor: pointer;
  float: right;
  background: #64ADDA;
  border-radius: 5px;
  height: 39px;
  margin-right: 0;
  width: 100%;
  border: 1px solid #64ADDA;
}

.upload-file-box > input[type="file"] {
  position: relative;
  padding: 0;
  background: #FFFFFF;
  border: 2px dashed #D6D6D6;
  box-sizing: border-box;
  border-radius: 5px;
  height: 220px;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  left: 0;
  z-index: 1;
}

.upload-file-box > img {
  vertical-align: middle;
  margin-left: auto;
}

.content-upload {
  font-size: 13px;
  text-align: center;
  color: #A9A9A9;
  cursor: pointer;
  margin-top: 0;
  vertical-align: middle;
  position: relative;
  top: -50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
}

.upload-file-box-full {
  padding: 0;
  border: 2px dashed #D6D6D6;
  box-sizing: border-box;
  border-radius: 5px;
  height: 220px;
  cursor: pointer;
  background: #fcfcfc center center;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.upload-file-box-full > input[type="file"]::-webkit-file-upload-button {
  padding: 5px 12px;
  color: #fff;
  font-size: 1rem;
  transition: all .4s;
  cursor: pointer;
  float: right;
  background: #64ADDA;
  border-radius: 5px;
  height: 39px;
  margin-right: 0;
  width: 100%;
  border: 1px solid #64ADDA;
}

.upload-file-box-full > input[type="file"] {
  position: relative;
  padding: 0;
  background: #FFFFFF;
  border: 2px dashed #D6D6D6;
  box-sizing: border-box;
  border-radius: 5px;
  height: 220px;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  left: 0;
  z-index: 1;
}

.upload-file-box-full > img {
  vertical-align: middle;
  margin-left: auto;
}

.content-upload-full {
  font-size: 15px;
  text-align: center;
  color: #A9A9A9;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  top: -50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
}

.label-upload {
  font-size: 16px;
  text-align: left;
  margin-bottom: 2px;
  color: #A9A9A9;
  cursor: pointer;
}

.error-text {
  color: red;
  font-size: 12px;
  word-break: break-all;
}

.upload-file-box-full:hover, .upload-file-box:hover {
  border-color: var(--primary-color);
}
</style>