<template>
  <div>
    <b-row v-if="label || required">
      <b-col class="col-auto" style="padding-right: 5px!important;" v-if="label">
        <label class="label-for-input landing-text">
          {{ label }}
        </label>
      </b-col>
      <b-col class="col-2 text-left" style="padding-left: 5px!important;" v-if="required">
        <strong style="color: red">*</strong>
      </b-col>
    </b-row>
    <div class="relative">
      <slot></slot>
      <small v-if="error" class="error"> {{
          $t('common.requiredField')
        }}</small>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    error: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.relative {
  position: relative;
}

.error {
  padding-top: 8px;
  position: absolute;
  color: #FC6C6B;
}

.label-for-input {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 0;
  color: var(--secondary-color) !important;
}

.landing-text {
  color: var(--secondary-color) !important;
}
</style>