<template>
  <div>
    <b-modal id="modal-verify-account" size="lg" hide-footer no-close-on-backdrop no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="text-primary">{{$t('account.verify_account.verify')}}</h5>
        <i class="fas fa-times hover-icon action-button" @click="closePopupVerifyAccount"></i>
      </template>
      <div>
        <div class="text-bold">{{$t('account.verify_account.for_efficient_use')}}
          {{$t('account.verify_account.verify_easy_way')}}
        </div>
        <div class="ml-4 mb-3">
          <div class="text-bold mt-3">{{$t('account.verify_account.terms_of_use')}}</div>
          <div>
            {{$t('account.verify_account.user_must_not_send')}}
            <ul>
              <li>{{$t('account.verify_account.game_invite_msg')}}</li>
              <li>{{$t('account.verify_account.msg_that_use_impolite')}}</li>
              <li>{{$t('account.verify_account.msg_about_following')}}</li>
              <li>{{$t('account.verify_account.msg_deceptive')}}</li>
            </ul>
          </div>
          <div>
            <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                v-model="is_consent"
            >
              {{$t('account.verify_account.agree_to_act')}}
            </b-form-checkbox>
          </div>
        </div>
        <div class="text-center mt-3 mb-3">
          <b-button
              variant="outline-primary"
              class="mr-2 w-25"
              :disabled="!is_consent"
              @click="verifyPersonal('personal')"
          >
            {{$t('account.verify_account.personal')}}
          </b-button>
          <b-button
              variant="outline-primary"
              class="w-25"
              :disabled="!is_consent"
              @click="verifyJuristic('juristic')"
          >
            {{$t('account.verify_account.juristic')}}
          </b-button>
        </div>
      </div>
    </b-modal>
    <verify-form-personal :juristic="juristic_type" :visible="is_visible" @update="updateSuccess"
                          @close="closeFormVerify" :customer-profile="customerProfile" :setting-brand="settingBrand"/>
    <verify-form-juristic :juristic="juristic_type" :visible="is_visible_juristic" @update="updateSuccess"
                          :customer-profile="customerProfile"
                          :setting-brand="settingBrand"
                          @close="closeFormVerify"/>
  </div>
</template>

<script>
import VerifyFormPersonal from "@/views/account/verify/verifyFormPersonal.vue";
import VerifyFormJuristic from "@/views/account/verify/verifyFormJuristic.vue";

export default {
  name: "verify-modal",
  components: {VerifyFormJuristic, VerifyFormPersonal,},
  props: {
    customerProfile: {
      type: Object
    },
    settingBrand: {
      type: Object
    }
  },
  data() {
    return {
      is_consent: false,
      juristic_type: '',
      is_visible: false,
      is_visible_juristic: false
    }
  },
  methods: {
    verifyPersonal(type) {
      this.juristic_type = type
      this.is_visible = true
      this.$bvModal.hide('modal-verify-account')
    },
    verifyJuristic(type) {
      this.juristic_type = type
      this.is_visible_juristic = true
      this.$bvModal.hide('modal-verify-account')
    },
    updateSuccess(e) {
      this.$emit('update', e)
    },
    closeFormVerify() {
      this.is_visible = false
      this.is_visible_juristic = false
      this.is_consent = false
      this.juristic_type = ''
    },
    closePopupVerifyAccount() {
      this.is_consent = false
      this.juristic_type = ''
      this.is_visible = false
      this.is_visible_juristic = false
      this.$bvModal.hide('modal-verify-account')
    }
  }
}
</script>

<style scoped>

</style>