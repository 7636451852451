<template>
  <div id="upload-file-create-sender">
    <div v-if="!isButtonRight" class="d-flex align-items-center justify-content-between" :class="iconTab? 'mb-2': ''">
      <div v-if="label">
        <label class="label-upload text-secondary">{{ label }}</label>
        <strong v-if="required" style="color: red" class="ml-2">*</strong>
      </div>
      <div class="text-end">
        <div class="sub-title-info">
          {{ textInfo || '' }}
        </div>
      </div>
    </div>
    <div v-else class="d-flex align-items-center justify-content-between" :class="iconTab? 'mb-2': ''">
      <div class="d-flex">
        <div v-if="label">
          <label class="label-upload text-secondary">{{ label }}</label>
          <strong v-if="required" style="color: red" class="ml-2">*</strong>
        </div>
        <div class="text-end">
          <div class="sub-title-info ml-2 mt-1">
            {{ textInfo || '' }}
          </div>
        </div>
      </div>
      <b-button variant="primary" @click="updateAction">{{ $t('setting.multiple.downloadLOA') }}</b-button>
    </div>

    <!-- preview file   -->

    <div class="content-box">

      <!--   preview single file   -->

      <div align="center" class="custom-spinner" v-if="loadingImg && !maxFile">
        <b-spinner class="mt-5 mb-1 text-primary" style="width: 4rem; height: 4rem">
        </b-spinner>
      </div>
      <div class="wrapper-box" v-if="single && previewImage.length > 0"
           v-for="(img, index) in previewImage"
           :key="index">
        <div class="preview-file-box w-100">
          <div class="box w-100">
            <i class="fas fa-times close"
               v-if="closeIcon && !loadingImg"
               @click="clearImage(index)"/>
            <div v-if="checkTypeFile(img?.link) && !loadingImg" style="max-width: 100%">
              <img src="@/assets/images/icon/checkmark-outline.svg" alt="no file" width="70">
              <p class="m-0 mt-2 hide-text"
                 style="font-size: 32px; color: #9F9F9F; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                {{ img?.name }}</p>
            </div>
            <!----- when file be PDF or Docx ---->
            <div class="doc-box" style="max-width: 100%" v-else>
              <img src="@/assets/images/icon/checkmark-outline.svg" alt="no file" width="70">
              <p class="m-0 mt-2 hide-text"
                 style="font-size: 32px; color: #9F9F9F; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                {{ img?.name }}</p>
            </div>
            <button class="btn btn-outline mt-2"
                    @click="checkTypeFile( previewImage[index]?.link) && !loadingImg ? fullScreen(img?.link):viewFile(img?.link)">
              <p class="m-0">{{ $t('setting.multiple.watchFile') }}</p>
            </button>
          </div>
        </div>
      </div>

      <!--   attach multiple file && preview single file   -->

      <div v-if="closeIcon && multiple && maxFile" class="content-box-grid">
        <div v-for="(_, index) in maxFile" :key="index" :class="{'block-hover':previewImage[index]}"
             class="upload-file-box-full" style="position: relative">
          <i class="fas fa-times close close-icon-full"
             v-if="previewImage[index]"
             @click="clearMultiImage(index)"
             style="position: absolute; top: 10px; right: 10px; z-index: 99"/>
          <input
              :style="previewImage[index]&&customInputStyle"
              :disabled="previewImage[index]"
              :ref="`ImgMultiComponent_${index}`"
              type="file"
              @change="($event) => handleFileChangeMultiple($event, `ImgMultiComponent_${index}`,index)"
              :accept="accept || 'image/*'">
          <div class="content-upload-full" :style="previewImage[index]?customInputStyle:emptyInputStyle">
            <div class="custom-multi-spinner" :id="`custom-multi-spinner-${index}`">
              <b-spinner class="text-primary"></b-spinner>
            </div>
            <div v-if="previewImage[index]" class="content-upload-full__uploaded-img">
              <div v-if="checkTypeFile( previewImage[index]?.link)"
                   class="d-flex justify-content-center align-items-center" style="flex-direction: column">
                <img src="@/assets/images/icon/checkmark-outline.svg" alt="no file" width="60">
                <p class="m-0 mt-2"
                   style="color: #9F9F9F; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-size: 24px; max-width: 200px">
                  {{ previewImage[index]?.name }}</p>
              </div>
              <div class="doc-box d-flex justify-content-center align-items-center" style="flex-direction: column"
                   v-else>
                <img src="@/assets/images/icon/checkmark-outline.svg" alt="no file" width="60">
                <p class="m-0 mt-2"
                   style="color: #9F9F9F; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-size: 24px; max-width: 200px">
                  {{ previewImage[index]?.name }}</p>
              </div>
              <button class="btn btn-outline mt-2"
                      @click="checkTypeFile( previewImage[index]?.link)?fullScreen(previewImage[index]?.link):viewFile(previewImage[index]?.link)">
                <p class="m-0">{{ $t('setting.multiple.watchFile') }}</p>
              </button>
            </div>
            <div v-if="!previewImage[index]">
              <img src="@/assets/images/icon/upload-file.svg" alt="no file" width="60">
              <div class="mt-2">
                <label>{{ subLabel }}</label>
                <div :class="`${limitMultipleFileError[`max_error_${index}`] || ''} error-text`">
                  <p class="m-0" style="font-size: 14px; padding-left: 14px; padding-right: 14px">
                    {{ limitMultipleFileError[`max_error_${index}`] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--   attach single file   -->

      <div v-if="closeIcon && single && !maxFile && previewImage.length < 1" class="upload-file-box-full">
        <input ref="ImgMutiComponent" type="file" @change="handleFileChange" :accept="accept || 'image/*'">
        <div class="content-upload-full">
          <img src="@/assets/images/icon/upload-file.svg" alt="no file" width="70" v-if="!loadingImg">
          <div class="mt-2" v-if="!loadingImg">
            <label>{{ subLabel }}</label>
            <div :class="`${limitFileError || ''} error-text`">
              <p class="m-0" style="font-size: 14px; padding-left: 14px; padding-right: 14px">{{ limitFileError }}</p>
            </div>
          </div>
        </div>
      </div>

      <!--  set img full screen     -->
      <b-modal id="full-screen-modal" :size="is_full_file ? 'xl': 'lg'"
               :visible="is_fullscreen || is_full_file"
               hide-footer
               hide-header
               no-close-on-esc
               no-close-on-backdrop>
        <div class="close-icon-full-container" @click="closeFullScreen">
          <div class="close-icon-full">
            <i class="fas fa-times"/>
          </div>
        </div>
        <div v-if="loadingIframe" class="d-flex justify-content-center align-items-center"
             style="margin-top: 30px; margin-bottom: 30px; height: 450px">
          <b-spinner variant="primary" style="height: 74px; width: 74px"></b-spinner>
        </div>
        <div class="box-full-screen-img" v-if="is_fullscreen">
          <img :src="img_full" scrolling="auto"/>
        </div>
        <iframe :src="file_full"
                v-else
                ref="fileIframe"
                style="width:100%;height:80vh;border:2px solid #FFFFFF;border-radius: 14px;"
                scrolling="auto"/>
      </b-modal>
    </div>
  </div>
</template>

<script>

import resourceApi from "@/repository/resourceApi";
import common from "@/common/functions"

export default {
  name: "uploadImageMultipleComponent",
  props: {
    textInfo: {
      type: String,
      default: ''
    },
    single: {
      type: Boolean,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      required: false
    },
    subLabel: {
      type: String,
      required: false
    },
    value: {
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    previewClassName: {
      type: String,
      required: false,
    },
    iconCloseClass: {
      type: String,
      required: false,
    },
    sizeFile: {
      type: Number,
      required: false,
    },
    accept: {
      type: String,
      required: false,
    },
    iconTab: {
      type: Boolean,
      required: false,
    },
    activeIconTab: {
      type: String,
      required: false,
    },
    sizeFileKB: {
      type: Number,
      required: false,
    },
    closeIcon: {
      default: true,
      required: false
    },
    maxFile: {
      type: [Boolean, Number],
      default: false
    },
    isButtonRight: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      cspContent: '',
      loadMultiImg: 0,
      previewImage: [],
      limitFileError: '',
      limitMultipleFileError: {},
      loadingIframe: false,
      is_fullscreen: false,
      is_full_file: false,
      file_full: null,
      img_full: null,
      loadingImg: false
    }
  },
  mounted() {
    if (this.value) {
      this.previewImage = this.value
    }
    if (this.maxFile) {
      for (let i = 0; i < this.maxFile; i++) {
        this.limitMultipleFileError[`max_error_${i}`] = ''
      }
    }
  },
  emits: ['input'],
  computed: {
    emptyInputStyle() {
      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }
    },
    customInputStyle() {
      return {
        zIndex: 0,
        cursor: 'default'
      }
    }
  },
  methods: {
    customHandleFileChange(imgDetail) {
      imgDetail?.uploadType === 'single' ? this.loadingImg = true : this.loadMultiple('flex')
      common.resizeImage(imgDetail?.file, (resizedDataURL) => {
        const buffer = resizedDataURL.split(',')
        let mapData = {
          mimetype: imgDetail?.file?.type,
          buffer: buffer[1],
        }
        this.uploadFile(mapData, imgDetail)
      });
    },
    callBackHandleFileChange(imgDetail) {
      imgDetail?.uploadType === 'single' ? this.loadingImg = true : this.loadMultiple('flex')
      const reader = new FileReader();
      reader.onload = e => {
        let imageBuffer = e?.target?.result.split(',')
        const isDocx = imgDetail?.file?.type.includes('.document') && imgDetail?.file?.type.includes('application/')
        let mapData = {
          mimetype: isDocx ? 'application/docx' : imgDetail?.file?.type,
          buffer: imageBuffer[1],
        }
        this.uploadFile(mapData, imgDetail)
      }
      reader.readAsDataURL(imgDetail?.file);
    },
    uploadFile(mapData, imgDetail) {
      const file = imgDetail?.file ? imgDetail?.file : null
      const uploadType = imgDetail?.uploadType ? imgDetail?.uploadType : 'single'
      const img_ref = imgDetail?.img_ref ? imgDetail?.img_ref : null
      const index = imgDetail?.index ? imgDetail?.index : null
      resourceApi.uploadImage(mapData).then((response) => {
            if (uploadType === 'single') {
              this.previewImage.push({link: response.link, name: file.name})
              this.$refs.ImgMutiComponent.value = ''
            } else {
              this.limitMultipleFileError = {
                ...this.limitMultipleFileError,
                [`max_error_${index}`]: ''
              }
              if (this.previewImage.length <= this.maxFile) {
                this.previewImage.push({link: response.link, name: file.name});
                this.$emit('input', this.previewImage);
              }
              this.$refs[img_ref][0].value = ''
            }
            this.$emit('input', this.previewImage)
          }
      ).catch((err) => {
        if (uploadType === 'single') {
          this.$refs.ImgMutiComponent.value = ''
          if (err.response.status === 413) {
            this.limitFileError = this.$t('setting.multiple.err.maxSize')
          }
        } else {
          this.$refs[img_ref][0].value = ''
          if (err.response.status === 413) {
            this.limitMultipleFileError = {
              ...this.limitMultipleFileError,
              [`max_error_${index}`]: this.$t('setting.multiple.err.maxSize')
            };
          }
        }
      }).finally(() => {
        if (uploadType === 'single') {
          this.loadingImg = false
        } else {
          this.loadMultiple('none')
          this.loadMultiImg = this.previewImage.length
        }
      })
    },
    checkDocx() {
      const filterAccept = this.accept
      const splitAccept = filterAccept.split(',')
      const findType = splitAccept.map(item => item.replace(' ', '').replace('.', ''))
      return findType.includes('docx')
    },
    viewFile(link) {
      // this.file_full = `https://docs.google.com/gview?url=${encodeURIComponent(link)}&embedded=true`;
      // this.file_full = `https://docs.google.com/viewer?url=${encodeURIComponent(link)}&embedded=true`;
      // this.file_full = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(link)}`;
      this.is_full_file = true
      if (link.includes('.document') || link.includes('.docx')) {
        this.file_full = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(link)}`
      } else {
        this.file_full = link
      }
    },
    loadMultiple(style) {
      const spinner = document.querySelector(`#custom-multi-spinner-${this.loadMultiImg}`)
      spinner.style.display = style;
    },
    handleFileChangeMultiple(e, img_ref = null, index) {
      let file = e.target.files[0];
      const imgDetail = {
        file: file,
        uploadType: 'multi',
        img_ref: img_ref,
        index: index,
      }
      const fileSizeInMegabytes = file?.size ? file.size / (1024 * 1024) : 11;
      const accept = ['application/pdf', 'image/png', 'image/jpeg'];
      if (this.checkDocx()) {
        const isDocx = file.type.includes('.document') && file.type.includes('application/')
        if (accept.includes(file.type) || isDocx) {
          if (fileSizeInMegabytes.toFixed(2) <= 10) {
            this.callBackHandleFileChange(imgDetail)
          } else {
            const accept = ['image/png', 'image/jpeg'];
            if (accept.includes(file.type)) {
              this.customHandleFileChange(imgDetail)
            } else {
              this.limitMultipleFileError = {
                ...this.limitMultipleFileError,
                [`max_error_${index}`]: this.$t('setting.multiple.err.maxSize')
              };
            }
          }
        }
      } else if (accept.includes(file.type)) {
        if (fileSizeInMegabytes.toFixed(2) <= 10) {
          this.callBackHandleFileChange(imgDetail)
        } else {
          const accept = ['image/png', 'image/jpeg'];
          if (accept.includes(file.type)) {
            this.customHandleFileChange(imgDetail)
          } else {
            this.limitMultipleFileError = {
              ...this.limitMultipleFileError,
              [`max_error_${index}`]: this.$t('setting.multiple.err.maxSize')
            };
          }
        }
      } else {
        this.limitMultipleFileError = {
          ...this.limitMultipleFileError,
          [`max_error_${index}`]: this.$t('setting.multiple.err.notSupport')
        };
      }
    },
    handleFileChange(e) {
      const file = e.target.files[0]
      const imgDetail = {
        file: file,
        uploadType: 'single',
      }
      const fileSizeInMegabytes = file?.size ? file.size / (1024 * 1024) : 11;
      const accept = ['application/pdf', 'image/png', 'image/jpeg'];
      if (this.checkDocx()) {
        const isDocx = file.type.includes('.document') && file.type.includes('application/')
        if (isDocx || accept.includes(file.type)) {
          if (fileSizeInMegabytes.toFixed(2) <= 10) {
            this.limitFileError = ''
            this.callBackHandleFileChange(imgDetail)
          } else {
            const accept = ['image/png', 'image/jpeg'];
            if (accept.includes(file.type)) {
              this.customHandleFileChange(imgDetail)
            } else {
              this.limitFileError = this.$t('setting.multiple.err.maxSize')
            }
          }
        }
      } else if (accept.includes(file.type)) {
        if (fileSizeInMegabytes.toFixed(2) <= 10) {
          this.limitFileError = ''
          this.callBackHandleFileChange(imgDetail)
        } else {
          const accept = ['image/png', 'image/jpeg'];
          if (accept.includes(file.type)) {
            this.customHandleFileChange(imgDetail)
          } else {
            this.limitFileError = this.$t('setting.multiple.err.maxSize')
          }
        }
      } else {
        this.limitFileError = this.$t('setting.multiple.err.notSupport')
      }
    },
    clearImage(index) {
      this.previewImage.splice(index, 1)
      this.$emit('input', this.previewImage)
      this.limitFileError = ''
    },
    clearMultiImage(index) {
      this.previewImage.splice(index, 1)
      this.$emit('input', this.previewImage)
      this.loadMultiImg = this.previewImage.length
      this.limitMultipleFileError = {
        ...this.limitMultipleFileError,
        [`max_error_${index}`]: ''
      };
    },
    fullScreen(img) {
      this.is_fullscreen = true
      this.img_full = img
    },
    closeFullScreen() {
      this.is_full_file = false
      this.file_full = null
      this.is_fullscreen = false
      this.img_full = null
      this.loadingIframe = false
    },
    checkTypeFile(file) {
      let type = file.split(/[#?]/)[0].split('.').pop().trim();
      let accept = ['jpeg', 'jpg', 'png', 'svg', 'gif', 'tiff'];
      if (accept.includes(type)) {
        return true
      } else {
        return false
      }
    },
    updateAction () {
      this.$emit('update', true)
    }
  }
  ,
  watch: {
    'value'(newData) {
      this.previewImage = newData
    }
  }
}
</script>

<style lang="scss">
#full-screen-modal {
  .hide-text-container {
    max-width: 300px;
  }

  .close-icon-full-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .close-icon-full {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgb(149, 0, 0);
      z-index: 1;
      cursor: pointer;
      color: #ffffff;
      opacity: 1;
    }
  }

  .close-icon-full:hover {
    background-color: rgb(108, 94, 94);
    opacity: 1;
  }

  .box-full-screen-img {
    display: flex;
    vertical-align: middle;
    justify-content: center;

    img {
      width: 100%;
      object-fit: contain;
      margin-top: 2em;
    }
  }
}

#upload-file-create-sender {
  .custom-multi-spinner {
    display: none;
    position: absolute;
    width: 90%;
    height: 80%;
    justify-content: center;
    align-items: center;
    background-color: white;
    bottom: 30px;
  }

  .content-upload-full__uploaded-img {
    position: relative;
    z-index: 99;
  }

  .close-icon-full {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgb(149, 0, 0);
    z-index: 1;
    cursor: pointer;
    color: #ffffff;
    opacity: 1;
  }

  .close-icon-full:hover {
    background-color: rgb(108, 94, 94);
    opacity: 1;
  }

  .sub-title-info {
    color: #35b1e8;
  }

  .wrapper-box {
    padding: 0;
    border: 2px dashed #D6D6D6;
    box-sizing: border-box;
    border-radius: 5px;
    height: 220px;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  .custom-spinner {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .content-box {
    position: relative;
    display: inline-flex;
    flex-direction: unset;
    flex-flow: row wrap;
    border-radius: 10px;
    vertical-align: middle;
    text-align: center;
    justify-content: flex-start;
    width: 100%;
  }

  .content-box-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .preview-file-box {
    position: relative;
  }

  .preview-file-box > .box {
    width: 200px;
    padding: 30px 10px 0px 10px;
  }

  .preview-file-box > .box > .close {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgb(149, 0, 0);
    position: absolute;
    top: 10px;
    right: 19px;
    text-align: center;
    line-height: 24px;
    z-index: 10;
    cursor: pointer;
    color: #ffffff;
    opacity: 1;
  }

  .preview-file-box > .box > .close:hover {
    background-color: rgb(108, 94, 94);
    opacity: 1;
  }

  .preview-file-box > .box > .doc-box {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }


  .upload-file-box-full {
    padding: 0;
    border: 2px dashed #D6D6D6;
    box-sizing: border-box;
    border-radius: 5px;
    height: 220px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  .upload-file-box-full > input[type="file"]::-webkit-file-upload-button {
    padding: 5px 12px;
    color: #fff;
    font-size: 1rem;
    transition: all .4s;
    cursor: pointer;
    float: right;
    background: #64ADDA;
    border-radius: 5px;
    height: 39px;
    margin-right: 0;
    width: 100%;
    border: 1px solid #64ADDA;
  }

  .upload-file-box-full > input[type="file"] {
    position: relative;
    padding: 0;
    background: #FFFFFF;
    border: 2px dashed #D6D6D6;
    box-sizing: border-box;
    border-radius: 5px;
    height: 220px;
    opacity: 0;
    width: 100%;
    cursor: pointer;
    left: 0;
    z-index: 1;
  }

  .upload-file-box-full > img {
    vertical-align: middle;
    margin-left: auto;
  }

  .content-upload-full {
    font-size: 15px;
    text-align: center;
    color: #A9A9A9;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
    top: -50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
  }

  .label-upload {
    font-size: 16px;
    text-align: left;
    margin-bottom: 2px;
    color: #A9A9A9;
    cursor: pointer;
  }

  .error-text {
    color: red;
    font-size: 12px;
    word-break: break-all;
  }

  .upload-file-box-full:hover {
    border-color: var(--primary-color);
  }

  .block-hover {
    border-color: #D6D6D6 !important;
  }
}
</style>