import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = '/sender-id'
export default {

    getAll(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePath}/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getTempAll(search = {}) {

        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`/sender-temps/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    createMultipleSender(data) {
        return service.apiAuth.post(`${resourcePath}/multi-create`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    checkMultipleSenderExist(data) {
        return service.apiAuth.post(`${resourcePath}/check-exists`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    create(data) {
        return service.apiAuth.post(`${resourcePath}/create`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    uploadImg(data) {
        const mapData = {
            uri: data
        }
        return service.apiAuth.post(`/upload-image`, mapData)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getById(id) {
        return service.apiAuth.get(`/get-by-id-customers/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },

    delete(id) {
        return service.apiAuth.delete(`/delete-sender-id/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },

}