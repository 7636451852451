<template>
  <div>
    <b-modal id="modal-verify-form-juristic" :visible="visible" size="lg" hide-footer no-close-on-backdrop
             no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="text-primary">{{ $t('account.verify_account.verify') }} :
          {{ $t('account.verify_account.juristic') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="closePopup"></i>
      </template>
      <div class="mb-4">
        <div id="form-verify-process">
          <form-wizard
              ref="props"
              hide-buttons
              :title="null"
              :subtitle="null"
              :start-index="1"
              step-size="xs">
            <template v-slot:step="props">
              <WizardStep
                  :tab="props.tab"
                  :transition="props.transition"
                  :index="props.index"
              >
                <small v-if="props.index === 0" style="font-size: 12px; margin: auto 2px">
                  <i class="fas fa-check text-white mt-1"/>
                </small>
              </WizardStep>
            </template>
            <tab-content :title="$t('account.verify_account.condition')"/>
            <tab-content :title="$t('account.verify_account.information_and_documents')"/>
            <tab-content :title="$t('account.verify_account.check')"/>
          </form-wizard>
        </div>
        <div class="mt-3 mb-3">
          <h5>{{ $t('account.verify_account.information_juristic') }}</h5>
          <div>
            <b-row>
              <b-col sm="12" md="6" lg="6" xl="6">
                <input-component :label-input="$t('account.verify_account.company_name')" required
                                 v-model="$v.form.company_name.$model"
                                 @keypress="isOnlyTHEnglishSpaceNumber"
                                 :require-label="validateState($v.form.company_name) !== null && !$v.form.company_name.required  ? $t('common.requiredField') : ''"
                                 :state="validateState($v.form.company_name)"/>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6">
                <input-component :label-input="$t('account.verify_account.tax_id')" required
                                 v-model="$v.form.tax_id.$model"
                                 :require-label="checkTaxID"
                                 :state="validateState($v.form.tax_id)" @keypress="chkNumber" max="13"/>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6">
                <input-component :label-input="$t('account.verify_account.phone')" required
                                 v-model="customerProfile.phone" @keypress="chkNumber" max="11" disabled-type/>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6">
                <input-component
                  :label-input="$t('account.verify_account.company_phone')"
                  v-model="form.company_phone"
                  @keypress="chkNumber" max="11"
                  :require-label="!(form.company_phone !== null && form.company_phone !== '') ? telStateCompany : true ? telInvalidCompany : ''"
                  :state="(form.company_phone !== null && form.company_phone !== '') ? telStateCompany : true"
                />
              </b-col>
              <b-col sm="12" md="12" lg="12" xl="12">
                <input-component :label-input="$t('subscription.checkout.address')" required
                                 v-model="$v.form.address.$model"
                                 :require-label="validateState($v.form.address) !== null && !$v.form.address.required  ? $t('common.requiredField') : ''"
                                 :state="validateState($v.form.address)"/>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6">
                <address-component
                    :label=" $t('subscription.checkout.sub_district')"
                    required>
                  <addressinput-subdistrict label="" v-model="$v.form.sub_district.$model"
                                            :class="$v.form.sub_district.$error ? 'input-req': 'mb-3' "
                  />
                </address-component>
              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6">
                <address-component :label=" $t('subscription.checkout.district')"
                                   required>
                  <addressinput-district label="" class="mb-3" v-model="$v.form.district.$model"
                                         :class="$v.form.district.$error ? 'input-req': 'mb-3' "/>
                </address-component>

              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6">
                <address-component :label=" $t('subscription.checkout.province')"
                                   required>
                  <addressinput-province label="" class="mb-3" v-model="$v.form.province.$model"
                                         :class="$v.form.province.$error ? 'input-req': 'mb-3' "/>
                </address-component>

              </b-col>
              <b-col sm="12" md="6" lg="6" xl="6">
                <address-component :label=" $t('subscription.checkout.zip_code')"
                                   required>
                  <addressinput-zipcode label="" class="mb-3" v-model="$v.form.zipcode.$model"
                                        :class="$v.form.zipcode.$error ? 'input-req': 'mb-3' "/>
                </address-component>
              </b-col>
            </b-row>
          </div>
          <div class="mb-3">
            <upload-file-signature-component @input="handleFileUploadCompanyCert"
                                             accept="image/png, image/jpeg, .pdf"
                                             :title="$t('account.verify_account.company_cert')"
                                             :info-content="$t('account.verify_account.upload_id_card_file_info_cintent')"
                                             :text-upload-content="$t('account.verify_account.company_cert_upload')"
                                             required
            />
          </div>
          <div class="mb-3" style="margin-top: 28px;">
            <upload-file-signature-component @input="handleFileUploadVatCert"
                                             accept="image/png, image/jpeg, .pdf"
                                             :title="$t('account.verify_account.vat_cert')"
                                             :info-content="$t('account.verify_account.upload_id_card_file_info_cintent')"
                                             :text-upload-content="$t('account.verify_account.vat_cert_upload')"
            />
          </div>
          <div style="margin-top: 28px;">
            <div class="flex-center justify-content-between">
              <b-form-checkbox
                  id="checkbox-1"
                  v-model="is_other_file"
                  name="checkbox-1"
              >
                <div class="d-flex">
                  <div>
                    {{ $t('setting.multiple.moreAttach') }}
                  </div>
                  <div class="text-info ml-2">
                    {{ $t('account.verify_account.upload_id_card_file_info_cintent') }}
                  </div>
                </div>
              </b-form-checkbox>
              <p class="m-0">{{ $t('setting.multiple.conditions.maxFile') }}</p>
            </div>
            <div v-if="is_other_file" class="mt-3 mb-3">
              <upload-file-multiple-signature-component
                  v-model="form.others"
                  :maxFile="form.others.length>4"
                  @input="setFileOthers"
                  :sub-label="$t('common.uploadTitle')"
                  accept="image/png, image/jpeg, .pdf"
                  custom_class="p-0"/>
            </div>
          </div>
          <div class="mt-3" v-if="settingBrand?.sms?.is_active || settingBrand?.email?.is_active">
            <h5>{{ $t('account.verify_account.receive_notification') }}</h5>
            <div class="text-info font-12">
              {{ $t('account.verify_account.receive_notification_remark') }}
            </div>
            <div class="d-flex mt-2">
              <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="form.notification_channels"
                  name="flavour-2"
              >
                <b-form-checkbox value="sms" v-if="settingBrand?.sms?.is_active">SMS</b-form-checkbox>
                <b-form-checkbox value="email" v-if="settingBrand?.email?.is_active">E-mail</b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </div>
          <div class="mt-4">
            <div class="d-flex">
              <b-form-checkbox class="mt-2" v-model="checkConfirm"></b-form-checkbox>
              <div class="warning-box w-100" >
              <b-row >
                  <b-col cols="1" class="d-flex justify-content-center">
                    <i class="fas fa-exclamation-circle" style="font-size: 26px;color:#FFC700"></i>
                  </b-col>
                  <b-col>
                    <div v-html="$t('account.verify_account.confirm_formation')"></div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center btn-layout mt-4 mb-0">
          <b-button class="button-wizard" variant="outline" @click="prev">
            <i class="fas fa-angle-left"/>
            {{ $t('common.previous') }}
          </b-button>
          <b-button class="button-wizard" variant="primary" @click="updateVerifyInfo"
                    :disabled="!checkConfirm || $v.form.$invalid || is_other_file && form.others.length <= 0">
            {{ $t('common.next') }}
            <i class="fas fa-angle-right"/>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import InputComponent from "@/components/common/inputComponent.vue";
import AddressComponent from "@/components/common/addressComponent.vue";
import {FormWizard, TabContent, WizardStep} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import UploadFileComponent from "@/components/common/uploadFileComponent.vue";
import UploadFileMultipleComponent from "@/components/common/uploadFileMultiple.vue";
import UploadFileSignatureComponent from "@/components/common/uploadFileSignatureComponent.vue";
import profileApi from "@/repository/profileApi";
import alert from "@/common/alert";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, required, requiredIf, email, sameAs} from "vuelidate/lib/validators";
import functionsCommon from "@/common/functions";
import settingApi from "@/repository/settingApi";
import UploadImageMultipleComponent from "@/components/common/uploadImgMultiple.vue";
import UploadFileCampaignComponent from "@/components/common/uploadFileCampaignComponent.vue";
import UploadFileContactComponent from "@/components/common/uploadFileContactComponent.vue";
import UploadFileMultipleSignatureComponent from "@/components/common/uploadFileMultipleSignatureComponent.vue";


export default {
  name: "verifyFormJuristic",
  components: {
    UploadFileMultipleSignatureComponent,
    UploadFileContactComponent,
    UploadFileCampaignComponent,
    UploadImageMultipleComponent,
    UploadFileSignatureComponent,
    UploadFileMultipleComponent,
    UploadFileComponent,
    AddressComponent,
    InputComponent,
    FormWizard,
    TabContent,
    WizardStep,
  },
  props: {
    juristic: {
      type: String,
      require: true,
      default: 'personal'
    },
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    customerProfile: {
      type: Object,
    },
    settingBrand: {
      type: Object,
    }
  },
  data() {
    return {
      form: {
        customer_type: "",
        company_name: "",
        tax_id: "",
        company_phone: "",
        county: "",
        address: "",
        sub_district: "",
        district: "",
        province: "",
        zipcode: "",
        company_cert: null,
        vat_cert: null,
        others: [],
        notification_channels: []
      },
      notificationChannelsList: [
        {text: 'SMS', value: 'sms'},
        {text: 'E-mail', value: 'email'},
      ],
      is_other_file: false,
      checkConfirm: false
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      company_name: {required},
      tax_id: {
        required: required,
        maxLength: maxLength(13),
        minLength: minLength(7)
      },
      address: {required},
      sub_district: {required},
      district: {required},
      province: {required},
      zipcode: {required},
      company_cert: {required}
    }
  },
  computed: {
    checkTaxID() {
      let errors = []
      if (!_.isNil(this.validateState(this.$v.form.tax_id))) {
        if (!this.$v.form.tax_id.$dirty) return errors
        !this.$v.form.tax_id.required && errors.push(this.$t('common.requiredField'))
        !this.$v.form.tax_id.maxLength && errors.push(this.$t('setting.multiple.err.fillMoreSeven'))
        !this.$v.form.tax_id.minLength && errors.push(this.$t('setting.multiple.err.fillMoreSeven'))
        return errors[0]
      }
      return errors[0]
    },
    telStateCompany() {
      return functionsCommon.telStateCompany(this.form.company_phone)
    },
    telInvalidCompany() {
      const data = functionsCommon.telInvalidCompany(this.form.company_phone)
      return this.$t(data)
    },
  },
  methods: {
    validateState(validation) {
      const {$dirty, $error} = validation;
      return $dirty ? !$error : null;
    },
    updateVerifyInfo() {
      let data = {
        customer_type: "juristic",
        company_name: this.form.company_name.trim(),
        tax_id: this.form.tax_id,
        company_phone: this.form.company_phone,
        address: {
          address: this.form.address,
          sub_district: this.form.sub_district,
          district: this.form.district,
          province: this.form.province,
          zipcode: this.form.zipcode,
          country: "th"
        },
        files: {
          company_cert: this.form.company_cert || null,
          vat_cert: this.form.vat_cert || null,
          others: this.form.others || []
        },
        notification_channels: this.form.notification_channels
      }

      profileApi.updateVerifyInfo(data).then(resp => {
        if (!!resp) {
          alert.addNewSuccess(this)
          this.closePopup()
          this.$emit('update', true)
        }
      }).catch((err) => {
        alert.addNewFailed(this, err.response.data.message)
      })
    },
    handleFileUploadCompanyCert(e) {
      this.form.company_cert = e
    },
    handleFileUploadVatCert(e) {
      this.form.vat_cert = e
    },
    closePopup() {
      this.$bvModal.hide('modal-verify-account')
      this.$bvModal.hide('modal-verify-form-juristic')
      this.$emit('close', true)
      this.$v.form.$reset();
      this.form = {
        customer_type: "",
        company_name: "",
        tax_id: "",
        company_phone: "",
        county: "",
        address: "",
        sub_district: "",
        district: "",
        province: "",
        zipcode: "",
        company_cert: null,
        vat_cert: null,
        others: [],
        notification_channels: [],
      }
    },
    prev() {
      this.$bvModal.show('modal-verify-account')
      this.$bvModal.hide('modal-verify-form-juristic')
      this.$emit('close', true)
      this.$v.form.$reset();
      this.form = {
        customer_type: "",
        company_name: "",
        tax_id: "",
        company_phone: "",
        county: "",
        address: "",
        sub_district: "",
        district: "",
        province: "",
        zipcode: "",
        company_cert: null,
        vat_cert: null,
        others: [],
        notification_channels: [],
      }
    },
    chkNumber(e) {
      return functionsCommon.checkNumber(e)
    },
    isOnlyTHEnglishSpaceNumber(e) {
      // @TODO: Fix this can type number
      return functionsCommon.isOnlyTHEnglishSpaceNumber(e)
    },
    setFileOthers(value) {
      this.form.others = value
    }
  }
}
</script>
<style scoped lang="scss">
#form-verify-process::v-deep {
  .vue-form-wizard {
    text-align: center !important;
    font-style: normal !important;
    padding-bottom: 10px !important;
  }

  .vue-form-wizard .wizard-header {
    padding: 0 !important;
  }

  .vue-form-wizard .wizard-tab-content {
    min-height: 0 !important;
    padding: 0 !important;
  }

  .vue-form-wizard .wizard-nav-pills > li > a {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 auto !important;
    color: #0003 !important;
    position: relative !important;
    top: 3px !important;
    font-style: normal !important;
  }

  .vue-form-wizard .wizard-icon-circle.checked {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: var(--primary-color) !important;
    border: 3px solid #FFFFFF !important;
    box-shadow: 0 0 0 3px var(--primary-color) !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: #AAAAAA !important;
    border: 0 solid #fff !important;
    box-shadow: 0 0 0 3px #AAAAAA !important;
    color: #FFFFFF !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle .wizard-icon {
    font-style: normal !important;
  }

  .wizard-icon-container {
    background-color: transparent !important;
  }

  .wizard-progress-bar {
    background-color: var(--primary-color) !important;
  }

  .wizard-progress-with-circle {
    background-color: #AAAAAA !important;
    width: 65% !important;
  }

  .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
    position: relative !important;
    top: 28px !important;
    height: 4px !important;
    display: inline-block !important;
  }

  .stepTitle.active {
    color: #2F2E2E !important;
  }

  @media (max-width: 768px) {
    .vue-form-wizard .wizard-tab-content {
      min-height: 100px;
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-header {
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-icon-circle {
      width: 1.6em !important;
      height: 1.6em !important;
    }

    .vue-form-wizard .wizard-icon-circle.checked {
      width: 1.6em !important;
      height: 1.6em !important;
    }
  }

  .input-req::v-deep input.th-address-input {
    border: 1px solid var(--danger) !important;
  }
}
</style>