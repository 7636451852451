import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = '/sms'
export default {

    findSMSTemplateAll(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePath}/search?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    viewSMS(data) {
        return service.apiAuth.get(`${resourcePath}/${data?.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    sendSMS(data) {
        const mapBody = {
            recipient: data?.recipient,
            sender_id: data?.sender_id,
            message: data?.message,
        }
        return service.apiAuth.post(`${resourcePath}/send`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },

    sendOTP(data) {
        const mapBody = {
            ref_code: data?.ref_code,
            token: data?.token,
            otp_code: data?.otp_code,
        }
        return service.apiAuth.post(`${resourcePath}/otp-verify`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getOriginator(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`/sender-id/list?${str}`).then(resp => {
            return resp?.data?.data
        }).catch(err => {
            throw err
        })
    },
    quickSendSMS(data, sender_name) {
        const mapBody = {
            tel: data?.tel,
            sender_name: sender_name,
            type: "mkt",
            message: data?.message,
        }
        return service.apiAuth.post(`/campaigns/quick-send`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    exampleFileDownload(typeFile) {
        return service.apiAuth.get(`/campaigns/example-file?file_type=${typeFile}`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    sendSMSUsingFile(data, sender_name, sendType) {
        const mapData = {
            name: data?.name,
            sender_name: sender_name,
            file: data?.file,
            is_header: data?.is_header,
            send_type: sendType,
            date: '',
            time: ''
        }
        return service.apiAuth.post(`/campaigns/send-file`, mapData).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    importFile(data) {
        return service.apiAuth.post(`campaigns/send-file-import`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    validateFile(data) {
        return service.apiAuth.post(`campaigns/send-file-validate`, data)
            .then(resp => {
                return resp
            }).catch(err => {
                throw err
            })
    },
    importAdd(data) {
        return service.apiAuth.post(`campaigns/send-using-file`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    checkSpamWordContent(word) {
        const mapData = {
            message: word
        }
        return service.apiAuth.post(`/securities/validate-spam`, mapData).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getContactGroupDropDownList() {
        const search = {
            status: 1
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`/contact/group/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    sendMassageCampaign(data) {
        return service.apiAuth.post(`/campaigns/campaigns-send`, data).then(resp => {
            return resp
        }).catch(err => {
            throw err
        })
    },
    getSmsTemplateList() {
        const search = {
            status: 1
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth
            .get(`/sms-templates/list?${str}`)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    getAvailableTagList() {
        return service.apiAuth.get(`/tag-templates/list`).then((resp) => {
            return resp.data;
        }).catch((err) => {
            throw err;
        });
    }
}